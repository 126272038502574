import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HomePage from "../components/HomePage"

const IndexPage = ({ data, location }) => {

    const localeID = 'en_US';

    return (
        <Layout location={location} localeID={localeID}>
            <Seo title="Welcome" />
            <HomePage localeID={localeID} events={data.events} news={data.news} stores={data.allStores} reviews={data.reviews}/>
        </Layout>
    )
  }
  
export default IndexPage

export const pageQuery = graphql`
query HomePageEN {
    allStores: allWpPost(
        filter: {categories: {nodes: {elemMatch: {slug: {eq: "stores"}}}}}
    ) {
        ...StoreSliderData
    }
    events: allWpPost(
      filter: {locale: {id: {eq: "en_US"}}, categories: {nodes: {elemMatch: {slug: {eq: "events"}}}}}
      sort: {fields: acfEvents___date, order: DESC}
    ) {
      nodes {
        ...EventSliderData
      }
    }
    news: allWpPost(
        filter: {locale: {id: {eq: "en_US"}}, categories: {nodes: {elemMatch: {slug: {eq: "news"}}}}}
        limit: 2
        sort: { fields: [date], order: DESC }
      ) {
        nodes {
          ...PostSliderData
          content
        }
    }
    reviews: allWpPost(
        filter: {locale: {id: {eq: "en_US"}}, categories: {nodes: {elemMatch: {slug: {eq: "reviews"}}}}}
        sort: { fields: [date], order: DESC }
      ) {
        nodes {
          ...ReviewSliderData
        }
    }
  }
`